import React from 'react'

import Layout from '../components/layout'

import { Link } from 'gatsby'

export default function services() {
  return (
    <Layout>
      <div className="services">
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="columns twelve">
                <h1 className="center-text">Our Services</h1>
                <p>Introduction to our story </p>
                <p>lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: '100px' }}>
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="four columns">
              <Link to="/services/servicing-mot">
                <div className="serviceBox">
                  <h4>Servicing & MOT Work</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/services/upgrades-tuning">
                <div className="serviceBox">
                  <h4>Upgrades & Tuning</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/services/bare-metal-respray">
                <div className="serviceBox">
                  <h4>Bare Metal Respray</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="four columns">
              <Link to="/services/full-restoration">
                <div className="serviceBox">
                  <h4>Full Restoration</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/services/race-car-preparation">
                <div className="serviceBox">
                  <h4>Race Car Preperation</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/services/repair-diagnostic">
                <div className="serviceBox">
                  <h4>Repairs & Diagnostics</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="four columns">
              <Link to="/sales">
                <div className="serviceBox">
                  <h4>Sales</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/services/insurance-valuation">
                <div className="serviceBox">
                  <h4>Insurance Valuation</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/services/wheel-alignment">
                <div className="serviceBox">
                  <h4>Wheel alignment & Corner Weighting</h4>
                  <hr></hr>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
